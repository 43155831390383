.newsFilters {
    padding: 24px 0;
    margin-bottom: 24px;
}

.newsWrap {
    display: flex;
    gap: 24px;
}

.pagerButton {
    padding: 10px 4px 8px 4px;
    width: 36px;
    line-height: 1.1;
}

.pageCount {
    font-size: 0.92rem !important;
    text-align: right;
    color: var(--mantine-color-gray-6);
}

@media (min-width: 1024px) {
    .newsWrap {
        flex-wrap: wrap;
    }
}

@media (min-width: 720px) and (min-width: 1023px) {
    .newsWrap {
        flex-wrap: wrap;
    }
}

@media (max-width: 719px) {
    .newsWrap {
        flex-direction: column;
    }
}
