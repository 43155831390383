.sectionPartners {
    border-top: 1px solid var(--mantine-color-violet-4);
    border-bottom: 1px solid var(--mantine-color-violet-4);
    padding: 24px 0;

    h2 {
        font-size: 2em;
    }

    p {
        margin: 0;
        padding-top: 12px;

        &:first-of-type {
            padding-top: 0;
        }
    }
}

.partnerLogo {
    padding: 6px;

    .logo {
        display: block;
        max-height: 68px;
        width: auto;
    }
}

@media (max-width: 767px) {
    .partnerLogo {
        .logo {
            max-height: 50px;
        }
    }
}