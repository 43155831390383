.homeBanner {
    padding: 48px 0;
}

.homeBannerIn {
    border-radius: 6px;

    h1 {
        line-height: 1;
        margin-bottom: 0.25em;
    }
}

.homeBannerImg {
    border-radius: 6px;
    background: {
        size: cover;
        position: center;
        repeat: no-repeat;
    }

    img {
        mask: url('/assets/mask-homeBanner.png');
        mask-size: 100% 100%;
        mask-repeat: no-repeat;
        mask-position: center;
    }
}

.homeBannerIntro {
    font-size: 1.2em;
    margin-bottom: 24px;
}

@media (max-width: 1023px) {
    .homeBannerImg {
        min-height: 200px;
    }
}

@media (min-width: 1024px) {
    .homeBannerImg {
        min-height: 300px;
    }

    .heroGrid {
        &.textImg {
            flex-direction: row;
        }
        
        &.imgText {
            flex-direction: row-reverse;
        }
    }
}

@media (min-width: 1280px) {
    .homeBannerImg {
        margin-left: 12px;
        min-height: 340px;

        img {
            display: block;
        }
    }
}