.singleCard {
    margin-bottom: 24px;
    position: relative;
    background: linear-gradient(180deg, transparent 0%, transparent 30%, var(--mantine-color-gray-0) 30%, var(--mantine-color-gray-0) 100%);
    display: block;
    flex-shrink: 1;
    clip-path: polygon(30px 0%, 100% 0, 100% 100%, 0 100%, 0 24px);

    img {
        display: block;
        width: 100%;
        object-fit: cover;
        aspect-ratio: 5 / 4;
        max-height: 380px;
        mask: url('/assets/mask-card.svg') no-repeat 0 0;
        mask-size: cover;
        transition: filter 0.3s ease;
        background-color: #fff;
    }

    a {
        color: inherit;
        text-decoration: none;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 1.25rem !important;
        transition: color 0.3s ease;
    }

    &:has(a):hover {
        img {
            filter: brightness(1.1);
        }

        .cta {
            transform: translateX(10px);
        }

        h3 {
            color: var(--mantine-color-violet-7);
        }
    }

    &:has(.cta) {
        padding-bottom: 32px;
    }

    &:has(img) {
        .cardBox {
            padding-top: 12px;
        }
    }
}

.cardBox {
    background-color: var(--mantine-color-gray-0);
    padding: 32px 18px 18px 18px;
    flex-grow: 1;
    flex-shrink: 2;
}

.cta {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    bottom: 16px;
    left: 16px;
    transition: transform 0.3s ease;

    p {
        margin-right: 16px;
        font-weight: 500;
    }

    svg {
        color: var(--mantine-color-violet-6);
    }
}

.badgeIcon {
    display: flex;
    align-items: center;
    padding-left: 22px;
    position: relative;
    color: #291C33;
    background-color: #fff;
    border-color: #E1E1E2;

    svg {
        position: absolute;
        left: 4px;
        top: 1px;
        width: 13px;
    }
}

@media (max-width: 767px) {
    .singleCard {
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
    }
}