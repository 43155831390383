.breadcrumbsSection {
    min-height: 32px;
    background-color: var(--mantine-color-gray-1);
    border-top: 1px solid var(--mantine-color-gray-3);
}

.breadcrumbs {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        margin-right: 8px;
        font-size: 0.72em;

        &:last-child {
            margin-right: 0;

            a {
                color: black;
            }

            svg {
                display: none;
            }
        }
    }

    a {
        text-decoration: none;
        color: var(--mantine-color-violet-5);
        display: flex;
        padding: 6px 0;
        align-items: center;

        &:hover {
            text-decoration: underline;
        }

        svg {
            width: 12px;
            height: 12px;
            margin-left: 8px;
            color: black;
        }
    }
}