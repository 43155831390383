.cardsWrap {
    display: flex;
    flex-wrap: wrap;
    // align-items: stretch;
    justify-content: space-between;
    margin-bottom: 16px;
}

.cardsTwoWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-left: 8px;
}

@media (min-width: 768px) {
    .cardsTwoWrap {
        & > div {
            flex-basis: calc(50% - 14px);
        }
    }
}
