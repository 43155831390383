.statContent {
    border-bottom: 1px solid var(--mantine-color-gray-3);
    margin-bottom: 6px;
    padding-bottom: 6px;
}

.statInfo {
    max-width: calc(100% - 80px);
}

.statIcon {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -12px;

    img {
        max-width: 72px;
        max-height: 72px;
        width: auto;
        height: auto;
        display: block;
    }
}