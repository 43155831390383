

.cardsWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    margin-bottom: 16px;
    gap: 22px;
}

.singlePerson {
    margin-bottom: 24px;
    background-color: transparent;
    position: relative;
    display: flex;

    img {
        display: block;
        height: 320px;
        width: 100%;
        object-fit: cover;
        aspect-ratio: 5 / 4;
        mask: url('/assets/mask-card.svg') no-repeat 0 0;
        mask-size: cover;
        transition: filter 0.3s ease;

        & + div {
            background: var(--mantine-color-gray-1) !important;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 1.25rem !important;
        transition: color 0.3s ease;
    }

    a {
        display: block;
        color: inherit;
        text-decoration: none;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }

    &:has(a):hover {
        img {
            filter: brightness(1.1);
        }

        .cta {
            transform: translateX(10px);
        }

        h3 {
            color: var(--mantine-color-violet-7);
        }
    }
}

.cardBox {
    background-color: var(--mantine-color-gray-1);
    flex-grow: 1;
}

.cta {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    position: absolute;
    bottom: 6px;
    left: 6px;
    right: 6px;
    transition: transform 0.3s ease;

    svg {
        color: var(--mantine-color-violet-6);
    }
}

.socialLink {
    display: flex;
    align-items: center;
    padding: 8px 0;
    color: var(--mantine-color-violet-6);
    text-decoration: none;
    font-size: 0.8em;
    font-weight: 700;
    margin-top: 24px;
    transition: all 0.25s ease-out;

    svg {
        margin-right: 12px;
        padding: 8px;
        color: #fff;
        width: 40px;
        height: 40px;
        background-color: var(--mantine-color-violet-6);
        border-radius: 2px;
        transition: all 0.25s ease-out;
    }

    &:hover {
        color: var(--mantine-color-violet-5);

        svg {
            background-color: var(--mantine-color-violet-5);
        }
    }
}

.bioPic {
    display: block;
    height: 320px;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 5 / 4;
    mask: url('/assets/mask-card.svg') no-repeat 0 0;
    mask-size: cover;
}

@media (min-width: 1024px) {
    .singlePerson {
        flex: 0 0 32%;
    }
}