.heroBanner {
    padding: 48px 0;
    background-color: var(--mantine-color-violet-5);
    color: #fff;
}

.heroBannerIn {
    padding: 24px 0;

    h1 {
        line-height: 1;
        margin-bottom: 0.25em;
    }
}

.heroBannerImg {
    display: flex;
    align-items: center;
    padding: 1px 0 8px 0;
    background: {
        size: cover;
        position: center;
        repeat: no-repeat;
    }

    img {
        display: block;
        border-radius: 6px;
        overflow: hidden;
    }
}

.heroBannerIntro {
    font-size: 1.2em;
    margin-bottom: 24px;
}

@media (min-width: 1280px) {
    .heroBannerImg img {
        mask: url('/assets/mask-heroBanner.png');
        mask-size: 100% 100%;
        mask-repeat: no-repeat;
        mask-position: center top;
    }
}